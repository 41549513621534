<!--
/**
 * Story list template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import store from '?/Stores';
    import { onBeforeRouteLeave } from 'vue-router';
    import { story as storyData } from '?/ProjectData';
    import { getAssetPathForStories } from '?/Composable/Tools';

    // Data
    const stories = computed(() => {
        let stories = Object.values(store.getters.stories)
            .filter((item) => {
                // Filter by `live` status
                if (!item.live) {
                    return false;
                }

                // Filter by points
                const currentStoryData = storyData.find((data) => {
                    return data.id == item.storyId;
                });

                if (currentStoryData) {
                    return true;
                }

                return false;
            });

        return stories;
    });

    // Functions
    const data = (id) => {
        return storyData.find((data) => {
            return data.id == id;
        });
    };

    const addActiveClass = (e) => {
        e.target.parentElement.parentElement.classList.add('active');
    };

    // Custom setup logic
    onBeforeRouteLeave((to, from, next) => {
        const delay = 1000;
        const story = stories.value.find(story => story.storyId == to.params.id);

        // If story is unseen, set a delay to give time for a transition animation
        if (story && !story.done) {
            return new Promise(resolve => setTimeout(resolve, delay))
                .then(() => next());
        } else {
            next();
        }
    });
</script>

<template>
    <div id="stories" class="stories-wrapper">
        <template v-for="story in stories">
            <div class="story"
                 :id="'story-' + story.storyId"
                 :class="'story-' + story.storyId + ((story.done) ? ' story-seen' : ' story-new')">
                <router-link :to="'/webapp/stories/' + story.storyId">
                    <figure class="story-image">
                        <span class="story-image-wrapper"
                              @click="e => addActiveClass(e)">
                            <span class="story-image-front"
                                  :style="'background-image: url(\'' + getAssetPathForStories() + '/story-' + story.storyId + '.jpg\');'">
                                <img :src="getAssetPathForStories() + '/story-' + story.storyId + '.jpg'"
                                     alt="" />
                            </span>
                            <span class="story-image-back"
                                  :style="'background-image: url(\'' + getAssetPathForStories() + '/story-card-back.jpg\');'">
                                <img :src="getAssetPathForStories() + '/story-card-back.jpg'"
                                     alt="" />
                            </span>
                        </span>
                    </figure>
                </router-link>
            </div>
        </template>
    </div>
</template>

<style scoped>
    .story-image {

        -webkit-perspective: 1000;
                perspective: 1000;

    }

    .story-image-wrapper {

        width: 100%;
        height: auto;
        aspect-ratio: 797 / 1175;

        display: block;
        position: relative;

        transition: all .3s linear;

    }

    .story-image-front,
    .story-image-back {

        width: 100%;
        height: auto;
        aspect-ratio: 797 / 1175;

        background: rgba(255, 255 ,255 , .85);

        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;

        transition: opacity .3s linear;

    }

    .story-image-front {

        opacity: 0;
        transform: rotateY(180deg);

    }

    .story-image-back {

        z-index: 1;

        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;

    }

    .story-image-wrapper.active {

        transform: rotateY(180deg);

    }

    .story-seen .story-image-wrapper.active,
    .story-seen .story-image-front,
    .story-seen .story-image-back {

        transform: none;

    }

    .story-seen .story-image-front,
    .story-image-wrapper.active .story-image-front {

        opacity: 1;
        z-index: 0;

    }

    .story-seen .story-image-back,
    .story-image-wrapper.active .story-image-back {

        opacity: 0;
        z-index: 0;

    }
</style>
