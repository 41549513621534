<!--
/**
 * Intro template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import store from '?/Stores';
    import { routeTo, getAssetPathForIntro } from '?/Composable/Tools';
    import AudioPlayer from '?/Component/Library/AudioPlayer';

    // Data
    const state = reactive({
        step: 1,
        stepCount: 4
    });
</script>

<template>
    <div class="intro-wrapper">
        <div class="intro-content">
            <template v-if="state.step === 1">
                <img src="/assets/layout/logo-full.png"
                     alt="Der Kreis von Goseck"
                     class="logo-full" />
                <h2>Danke!</h2>
                <p>
                    Danke! Ihre Reise kann sofort losgehen.<br />
                    Wenn Sie im Browser Ihre Standortdaten (GPS) freigeben, erhalten Sie individuelle Empfehlungen.
                </p>
                <p>
                    Keine Sorge: Ihre Daten werden weder gespeichert noch an den Server übertragen.
                </p>
            </template>
            <template v-if="state.step === 2">
                <h2>Ich grüße Sie!</h2>
                <p>
                    Mein Name ist Moritz.<br />
                    Ich freue mich, dass Sie sich meiner kleinen Herausforderung stellen. Bevor ich mich persönlich vorstelle, lassen Sie mich kurz die wichtigsten Funktionen dieser WebApp erklären.
                </p>
            </template>
            <template v-if="state.step === 3">
                <h2>Die Funktionen</h2>
                <h3 class="intro-icon stations">Icon: Stationen</h3>
                <p>
                    Aktivieren Sie die Stationen und lernen Sie die Region auf eine neue Weise kennen.
                </p>
                <h3 class="intro-icon stories">Icon: Storys</h3>
                <p>
                    Hören Sie sich hier die Geschichten vom „Kreis von Goseck“ an.
                </p>
                <h3 class="intro-icon news">Icon: News</h3>
                <p>
                    Sehen Sie ein Ausrufezeichen an der Glocke rechts oben, gibt es etwas Neues zu entdecken!
                </p>
            </template>
            <template v-if="state.step === 4">
                <h2>Lassen Sie uns beginnen!</h2>
                <p>
                    Wissen Sie eigentlich, worum wir hier spielen? Es geht um weit mehr, als eine nette Schnitzeljagd durch die Region. Aber um das zu verstehen, möchte ich Ihnen kurz etwas über mich erzählen … und darüber, was Sie erwartet.
                </p>
                <p>
                    <AudioPlayer :src="getAssetPathForIntro() + '/intro-1.mp3'" />
                </p>
                <p>
                    PS. Sie können diese kurze Einleitung jederzeit erneut unter Hilfe abrufen.
                </p>
            </template>
        </div>
        <div class="intro-controls">
            <button type="button"
                    class="intro-controls-previous"
                    v-if="state.step > 1"
                    @click="state.step--">
                {{ $t('intro.previous') }}
            </button>
            <button type="button"
                    class="intro-controls-next"
                    v-if="state.step < state.stepCount"
                    @click="state.step++">
                {{ $t('intro.next') }}
            </button>
            <button type="button"
                    class="intro-controls-final"
                    v-if="state.step === state.stepCount"
                    @click="routeTo('/webapp/stories')">
                {{ $t('intro.go-to-story') }}
            </button>
            <span class="intro-controls-meta">
                {{ state.step }}/{{ state.stepCount }}
            </span>
        </div>
    </div>
</template>
