/**
 * Get unseen stories
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */


/**
 * Get unseen stories
 *
 * @return {array} Unseen stories
 */
export const getUnseenStories = () => {
    return [];
};
