/**
 * Get asset path for intro
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { getAssetPath } from '?/Composable/Tool/GetAssetPath';


/**
 * Get asset path for intro
 *
 * @return {string} Asset path
 */
export const getAssetPathForIntro = () => {
    return getAssetPath() + '/intro';
};
